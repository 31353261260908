
import { computed, ComputedRef, defineComponent } from "vue";
import { AuthPageStyleInterface } from "./auth_container_interfaces";
import { authContainerDefaultStyle } from "./auth_container_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
  name: "AuthContainer",
  props: {
    /**
     * centers the container if true
     */
    isCentered: {
      type: Boolean,
      default: true,
    },
    /**
     * resizes the container to the full height
     */
    isFullHeight: {
      type: Boolean,
      default: false,
    },
    /**
     * the source for the background image if it isn´t set
     * the background color is used
     */
    backgroundImageSource: {
      type: String,
      required: false,
    },
    /**
     * Configuration Object for Auth page Style with
     * following attributes:
     * {
     *      ! backgroundColor?: string;
     *      ! imageObjectFit?: string;
     *      ! containerBackgroundColor?: string;
     *      ! shadow?: string;
     *      ! borderRadius?: string;
     *      ! paddingClasses?: string;
     * }
     */
    authPageStyle: {
      type: Object as () => AuthPageStyleInterface,
      default: {} as AuthPageStyleInterface,
    },
  },
  setup(props) {
    const finalAuthContainerStyle: ComputedRef<AuthPageStyleInterface> =
      computed(() => {
        return {
          ...authContainerDefaultStyle,
          ...getSpaceConfigStyle()?.authContainer,
          ...props.authPageStyle,
        };
      });

    return {
      finalAuthContainerStyle,
    };
  },
});
