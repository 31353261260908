import { AuthPageStyleInterface } from "./auth_container_interfaces";

export const authContainerDefaultStyle: AuthPageStyleInterface = {
    backgroundColor: "grey-lightest",
    containerBackgroundColor: "white",
    shadow: "shadow",
    borderRadius: "md",
    paddingClasses: "px-4 sm:px-6 md:px-8 pb-11", //padding top depending on fullheight
    imageObjectFit: "cover",
};
