
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import AuthContainer from "./containers/AuthContainer.vue";
import AvatarContainer from "@/components/helpers/avatarContainer/AvatarContainer.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "../../store/auth";
import { useHelpersStore } from "@/store/helpers";
import { useRoute, useRouter } from "vue-router";
import { getCookie } from "@/common/cookies";
import { findRouteGetParameter } from "@/common/route_functions";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";

export default defineComponent({
    name: "Registration",
    components: {
        AuthContainer,
        CustomButton,
        InputDefault,
        AvatarContainer,
    },
    setup() {
        const { t } = useI18n();
        const passwordObject = ref<any>({
            password: null,
            repeatPassword: null,
        });
        const { registerUserByInvitation } = useAuthStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { removeErrorMessage, setToastMessage } = useHelpersStore();
        const router = useRouter();

        const emailParam = findRouteGetParameter("email");
        const tokenParam = findRouteGetParameter("token");

        async function userRegister(): Promise<void> {
            setLoadingId("register-button");
            if (emailParam && tokenParam) {
                const response = await registerUserByInvitation({
                    email: emailParam,
                    token: tokenParam,
                    password: passwordObject.value.password,
                    password_confirmation: passwordObject.value.repeatPassword,
                });
                if (!response) {
                    setToastMessage(
                        ToastMessageType.ERROR,
                        t("register_failed")
                    );
                } else {
                    setToastMessage(
                        ToastMessageType.SUCCESS,
                        t("registration_success")
                    );
                }
            }
            removeLoadingId("register-button");
        }
        const route = useRoute();
        const buttonStyle = computed(() => {
            let style = {};
            if (route.name?.toString().includes("CustomerAuth")) {
                style = {
                    standardBackgroundColor: "black-lightest",
                    standardBackgroundHoverColor: "black-light",
                    standardBackgroundPressedColor: "black-light",
                    standardBorderPressedColor: "black-light",
                    standardBorderFocusColor: "black-light",
                };
            }
            return style;
        });

        onMounted(() => {
            if (getCookie("logged_in")) {
                router.push({ name: "NormAdministration" });
            }
        });

        return {
            passwordObject,
            userRegister,
            emailParam,
            removeErrorMessage,
            buttonStyle,
        };
    },
});
