import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative w-full h-full flex flex-row items-center", [
      _ctx.isFullHeight == false && _ctx.isCentered == false
        ? 'pl-0 md:pl-36 justify-center md:justify-start'
        : _ctx.isFullHeight == false && _ctx.isCentered
        ? 'justify-center'
        : '',
      `bg-${_ctx.finalAuthContainerStyle.backgroundColor}`,
    ]])
  }, [
    (_ctx.backgroundImageSource)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(["w-full h-full absolute hidden sm:flex bottom-0 top-0 right-0 left-20", `object-${_ctx.finalAuthContainerStyle.imageObjectFit}`]),
          src: _ctx.backgroundImageSource
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["transition-all flex flex-col items-center justify-start z-1 max-w-full w-full sm:w-127 relative", [
        `${_ctx.finalAuthContainerStyle.paddingClasses} ${_ctx.finalAuthContainerStyle.shadow}  bg-${_ctx.finalAuthContainerStyle.containerBackgroundColor}`,
        _ctx.authPageStyle?.paddingClasses
          ? ``
          : _ctx.isFullHeight
          ? `pt-11 sm:pt-32`
          : `pt-11`,
        _ctx.isFullHeight ? `h-full` : `h-full sm:h-auto max-h-full`,
        _ctx.isFullHeight == false
          ? `sm:rounded-${_ctx.finalAuthContainerStyle.borderRadius}`
          : '',
      ]])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}