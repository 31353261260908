
import AuthContainer from "@/components/layout/authContainer/AuthContainer.vue";
import { defineComponent } from "vue";
export default defineComponent({
    name: "Login",
    components: { AuthContainer },
    setup() {
        return {};
    },
});
